import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './scss/tile.module.scss';
import ArrowIcon from '../../shared-components/icons/ArrowIcon';

/**
 * @param props
 */
export default function Tile(props) {
    const { path, name, description, Icon } = props;

    return (
        <Link to={path} className={styles.tile}>
            <Icon className={styles.tileIcon} />
            <h2 className={styles.tileName}>{name}</h2>
            <p className={styles.tileDescription}>{description}</p>
            <ArrowIcon className={styles.tileArrow} />
        </Link>
    );
}

Tile.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired
};
