import PropTypes from 'prop-types';
import React from 'react';
import styles from './scss/home.module.scss';
import Tile from './tile.components';

/**
 * @param props
 */
export default function Home(props) {
    const tiles = props.routes
        .filter((route) => route.path !== '/')
        .map((route) => {
            return <Tile {...route} key={route.name} />;
        });

    return (
        <div className={styles.homepage}>
            <h1 data-cy="home-heading" className={styles.homepageTitle}>
                Playbook
            </h1>

            <p className={styles.homepageDescription}>
                Bring fans to your platforms with engaging experiences.
            </p>

            <div data-cy="home-menu" className={styles.homepageTiles}>
                {tiles}
            </div>
        </div>
    );
}

Home.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object)
};
